@import "../../styles/scss/mq.scss";
@import "../../styles/scss/variables.scss";

.Content{
    display: flex;
    min-height: 100vh;
      display: flex;
      width: 100%;
      flex-direction: column;

    @include mq($from: lg){
          flex-direction: row;
          align-items: stretch;
    }
}

.Visual {
    background-color: $primaryBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mq($from: lg){
        width:  50%;
        align-items: center;
    }

}

.Title {
  width: 100%;
  text-align: center;
  font-size: 60px;
  letter-spacing: .8px;
  text-transform: uppercase;
  color: $primaryGreen;
  font-weight: bold;
}

.Body{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($from: lg){
        width: 50%;
    }
}

.CarouselContainer{
  width: 100%;
  height: auto;
}

.CarouselText {
  font-size: 60px;
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .8px;
  color: $primaryGreen;
}

.Logo {
  position: relative;
  margin: 0 auto $space;

  @include mq($from: sm) {
    margin-bottom: $space;
  }
}

.Image {
  width: 120px;
  height: auto;
}

.ImmagineIntro{
  width: 480px;
  height: auto;
}

.Children {
  width: 360px;
}

.Form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    @include mq($from: md) {
      max-width: 90%;
    }
  
    @include mq($from: lg) {
      max-width: 75%;
    }
  
    @include mq($from: xl) {
      max-width: 60%;
    }
  
    @include mq($from: xxl) {
      max-width: 50%;
    }
}