@import "../../styles/scss/variables";

.Alert {
  margin-bottom: $space;

  &.General {
    text-align: center;
  }
}

.Ul {
  margin-bottom: 0;
}